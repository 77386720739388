<template>
  <b-modal
    id="edit-review-sidebar-modal"
    :visible="isDetailReviewSidebarActive"
    size="xl"
    title="Detail Membership parameter lots"
    hide-footer
    @change="val => $emit('update:isDetailReviewSidebarActive', val)">
    <b-card>
      <TitleTable
        titleTable="Detail Membership parameter lots"
        iconTable="fas fa-disease" />

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found">
        <template #cell(code)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ `${data.item.code}` }}</span>
          </div>
        </template>
        <template #cell(patients)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.patients == null ? 'not assigned' : data.item.patients
            }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              (data.item.status = 1 ? 'asignado' : 'generado')
            }}</span>
          </div>
        </template>
        <template #cell(barcode)="data">
          <div class="d-flex justify-content-center align-items-center">
            <barcode :value="data.item.code" :options="data.item.barcode" />
          </div>
        </template>
        <!-- <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'memberships')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Details'"
            @click="goMembership(data.item)">
            <i class="fas fa-poll-h icon-nm"></i>
          </b-button>

          <b-button
            v-if="$can('update', 'memberships')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Edit'"
            @click="editmembership(data.item)">
            <i class="fas fa-pen icon-nm"></i>
          </b-button>

          <b-button
            v-if="$can('destroy', 'memberships')"
            variant="outline-danger"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Delete'"
            @click="deletemembership(data.item)">
            <i class="fas fa-trash-alt icon-nm"></i>
          </b-button>
        </template> -->
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import axiosAC from '@/core/services/apiInsurance/admin/consults/membershipsLots'

import ToastNotification from '@/components/ToastNotification'
import SpinnerLoading from '@/components/SpinnerLoading'
import PaginationTable from '@/components/PaginationTable'
import TitleTable from '@/components/TitleTable'
import GoBack from '@/components/ButtonBack'
import Swal from 'sweetalert2'
import Barcode from 'vue-barcode'

import { ref } from '@vue/composition-api'
export default {
  components: {
    BCard,
    ToastNotification,
    PaginationTable,
    TitleTable,
    Barcode,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isDetailReviewSidebarActive',
    event: 'isDetailReviewSidebarActive',
  },
  props: {
    isDetailReviewSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'id',
        { key: 'code', label: 'Code', sortable: true },
        { key: 'patients', label: 'Patients', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'barcode', label: 'Barcode', sortable: true },
        // A virtual column made up from two fields
        // { key: 'actions', label: 'Actions' },
      ],
      items: [],
      dataGoMembershipLots: null,
      idGoMembershipLots: -1,
      loading: false,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 3,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      membershipFilter: null,
      facilityOptions: JSON.parse(atob(localStorage.getItem('setFacilityOptions'))),
    }
  },
  created() {
    this.generateBarcode()
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getmembership()
      } else {
        this.searchFilter(this.membershipFilter)
      }
    },
  },
  methods: {
    generateBarcode() {
      this.items.map(item => {
        item['barcode'] = {
          displayValue: true, // Muestra el valor en el código de barras
          height: 50, // Altura del código de barras
        }
      })
      this.getmembershipLotsPaginate()
    },
    getmembershipLotsPaginate() {
      axiosAC
        .membershipParameterDetailPagination(this.perPage, this.itemEdit.value.id, 1)
        .then(({ data: { data, total, current_page, ...res } }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
    },
    editmembership(item) {
      this.isDetailReviewSidebarActive = true
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)))
    },
    editmembershipSuccess(value) {
      this.isDetailReviewSidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    createmembership(status) {
      this.isAddNewmembershipSidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    deletemembership(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosAC.membershipParameterLotsDelete(item.id).then(response => {
            this.$refs.toast.success('membership type has been deleted successfully')
            this.pageChanged(this.currentPage.page)
          })
        }
      })
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosAC
          .membershipParameterDetailPagination(this.perPage, this.itemEdit.value.id, page)
          .then(({ data: { data, total, current_page, ...res } }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      } else {
        axiosAC
          .membershipParameterFilterPagination(this.perPage, page, this.membershipFilter)
          .then(({ data: { data, total, current_page, ...res } }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      }
    },
  },
}
</script>

<style></style>
