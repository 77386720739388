import { userAxios } from '../../index'

const membershipsParametersLotsAll = async id => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters-lots/index/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterLotsCreate = async data => {
  try {
    return await userAxios
      .post('insurance/membership-parameters-lots', data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterLotsUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/membership-parameters-lots/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterLotsDelete = async id => {
  try {
    return await userAxios
      .delete(`insurance/membership-parameters-lots/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterPagination = async (perPage, id, page) => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters-lots/all/${id}/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterDetailPagination = async (perPage, id, page) => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters-lots/codes/${id}/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/membership-parameters-lots/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/membership-parameters-lots/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  membershipsParametersLotsAll,
  membershipParameterLotsCreate,
  membershipParameterLotsUpdate,
  membershipParameterLotsDelete,
  membershipParameterPagination,
  membershipParameterFilterPagination,
  membershipParameterFilter,
  membershipParameterDetailPagination,
}
